body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html, #root, .app, .container, .appusertable, .companyLogo {
  width: 100%;
  height: 100%;
  background-image: url(../../static/media/whiteBG.521b7e60.jpg);
}


.container {
  font-size: 28px;
  color: rgb(33, 32, 32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
 
}
.companyLogo {
  width: 200px;
  height: 100px;
  justify-content: center;
  padding-bottom: 25px;
}

.loginForm {
 box-sizing: border-box;
 width: 100%;
 max-width: 400px;
 padding-left: 20px;
 padding-right: 20px;
 
}

.inputField {
 padding-top: 16px;
}

.input {
 box-sizing: border-box;
 outline: none;
 border: solid 2px #1189de;
 border-radius: 4px;
 color: #292929;
 width: 100%;
 padding: 12px;
 font-size: 14px;
 background: rgba(255, 255, 255, 1);
}

.submitButton {
 padding-top: 16px;
}


.btn {
 width: 100%;
 min-width: 280px;
 color: #565656;
 padding: 12px;
 font-size: 14px;
 font-weight: bold;
 border: solid 2px #1189de;
 border-radius: 4px;
 background: #fff;
 cursor: pointer;
}
.appusertable{
  font-size: 18px;
  color: rgb(213, 209, 221);
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

